// Circle graph colors
$circle100: #16da5d;
$circle80: #b1da16;
$circle60: #dacd16;
$circle40: #daa916;
$circle20: #da7c16;
$circle0: #d81f14;

// Standard colors
$primary: #585858;
$accent: rgb(6 148 68);
$grey: #bdbdbd;
$green: #90e890;

// Vars
$background: #f3f3f3;
$shadow: 8px 14px 2px -4px #dadada;
$shadowHover: 16px 20px 4px -6px #dadada;
$border: 2px dashed hsla(0, 0%, 50.2%, 0.47058);

body {
  background: $background;
}

nav {
  background: transparent;
  border-bottom: $border;
  padding: 15px;
  position: relative;

  .navbar-brand {
    width: 100%;
    height: 48px;
    max-width: 44px;
    background: url(../images/envelope.svg) no-repeat;
    background-size: contain;
    transition: background-image 0.2s ease-in-out;

    &:hover {
      background: url(../images/envelope-closed.svg) no-repeat;
      background-size: contain;
      transition: background-image 0.2s ease-in-out;
      // animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      // transform: translate3d(0, 0, 0);
      // backface-visibility: hidden;
      // perspective: 1000px;
    }

    // @keyframes shake {
    //   0% {
    //     transform: rotate(10deg);
    //   }
    //   10% {
    //     transform: rotate(-10deg);
    //   }
    //   20% {
    //     transform: rotate(1deg);
    //   }
    //   30% {
    //     transform: rotate(10deg);
    //   }
    //   40% {
    //     transform: rotate(1deg);
    //   }
    //   50% {
    //     transform: rotate(-10deg);
    //   }
    //   60% {
    //     transform: rotate(10deg);
    //   }
    //   70% {
    //     transform: rotate(-10deg);
    //   }
    //   80% {
    //     transform: rotate(1deg);
    //   }
    //   90% {
    //     transform: rotate(10deg);
    //   }
    //   100% {
    //     transform: rotate(0deg);
    //   }
    // }
  }

  .nav-link {
    &:hover {
      color: $accent !important;
    }
  }

  .toggle-nav-menu {
    &.hide {
      height: 0;
    }
    &.show {
      position: absolute;
      background: $background;
      display: flex;
      flex-flow: column;
      padding: 5px;
      right: 13px;
      bottom: -92px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border: $border;
      border-top: none;
      text-align: end;
      box-shadow: 9px 14px 2px -4px #dadada, 9px -3px 2px -4px #dadada;
      z-index: 2;
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  color: $primary;
}

.login-page {
  background: url(../images/envelopes-bg.png) no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  // // background: #000;
  height: 100%;
}

section {
  margin: 20px 0 60px;

  &.login {
    position: relative;
    max-width: 500px;
    // margin: 5em auto 0 5em;
    margin: 5em auto;
    z-index: 1;

    .card {
      width: 100%;
      max-width: 450px;
      padding: 10px 20px 0;
    }
  }
}

.dashed-border {
  border-radius: 10px;
  padding: 10px;
  border: $border;
}

.shadow-box {
  background: #fff;
  padding: 10px;
  box-shadow: 8px 14px 2px -4px #dadada;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadowHover;
    transition: 0.3s ease-in-out;
  }
}

.card-wrapper {
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;

  &.center {
    justify-content: center;
  }
}

.flex-wrapper {
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.no-wrap {
    flex-flow: row nowrap;
  }

  &.m-space {
    justify-content: flex-start;
    > * {
      margin-right: 10px;
    }
  }

  &.mobile-apart {
    justify-content: space-between;
    align-items: baseline;
  }

  &.align-center {
    align-items: center;
  }
}

.flex {
  display: flex;
  flex-flow: row nowrap;

  &.end {
    justify-content: flex-end;
  }

  &.wrap {
    flex-flow: row wrap;
  }

  &.column {
    flex-flow: column;
  }

  &.m-space {
    justify-content: flex-start;
    > * {
      margin-right: 10px;
    }
  }
}

.summary-card {
  max-width: 309px;
  min-width: 279px;
  margin: 10px 1.7%;

  .card {
    padding: 10px 0 0;
    margin: 10px 0;

    .summary-bottom {
      h3 {
        color: #fff;
      }
    }
  }
}

.card {
  width: 279px;
  margin: 10px 1.7%;
  box-shadow: $shadow;
  transition: 0.3s ease-in-out;
  align-self: flex-start;

  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadowHover;
    transition: 0.3s ease-in-out;
  }

  &.info {
    align-self: auto;
  }

  &.card-center {
    align-items: center;
    justify-content: center;
  }

  .tag {
    padding: 5px 8px;
    margin-left: auto;
  }

  .pin {
    color: $grey;
    font-size: 1.3em;

    .pinned {
      color: #ffbe00;
    }
  }

  .icon {
    text-align: center;
    font-size: 5em;
    color: $accent;
  }

  .quick-expand {
    transition: 0.2s ease-in-out;
  }

  .card-input-wrapper {
    width: 100%;
    height: 70px;
    display: inline-flex;

    &:first-of-type {
      border-top: $border;
      margin-top: 10px;
      padding-top: 10px;
    }

    > div {
      width: 100%;
      max-width: 150px;
      margin-right: 10px;
    }

    button {
      height: 39px;
      margin-top: 23px;
    }
  }
}

.envelope,
.new-envelope {
  .circle-graph {
    div:first-of-type {
      position: relative;
      border: 3px solid #fff;

      &::after {
        content: "";
        position: absolute;
        top: -8px;
        bottom: -8px;
        left: -8px;
        right: -8px;
        border-radius: 100px;
        border: $border;
      }
    }
  }
}

.circle-graph-wrapper {
  margin-top: 4em;
  margin-right: 3rem;
}

.circle-graph {
  .circle {
    position: relative;
    margin: 0 auto;
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #eae9e9;

    .mobile-line {
      display: none;
    }

    &::before {
      position: absolute;
      content: "";
      width: 170px;
      height: 170px;
      border-radius: 175px;
      background: #fff;
    }

    .amount {
      text-align: center;
      z-index: 1;

      h3 {
        margin-bottom: 0px;
      }
    }

    &.c100 {
      background-color: $circle100;
    }

    &.c80 {
      background-color: $circle80;
    }

    &.c60 {
      background-color: $circle60;
    }

    &.c40 {
      background-color: $circle40;
    }

    &.c20 {
      background-color: $circle20;
    }

    &.c0 {
      background-color: $circle0;
    }
  }
}

.pie-chart-wrapper {
  min-width: 60%;
  height: 70vw;
  max-height: 400px;
  padding: 20px;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  margin-bottom: 3rem;

  button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
  }
}

.mobile-calendar {
  display: none;
}

.date-range-container {
  justify-content: flex-end;
}
.date-wrapper {
  .react-datepicker {
    margin: 10px 1.7%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: $shadow;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
      box-shadow: $shadowHover;
      transition: 0.3s ease-in-out;
    }
    .react-datepicker__header {
      background: white;
      border-bottom: 1px dashed #aeaeae;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $accent;
    }
  }
}

.info-tag {
  padding: 15px 20px;
  font-size: 1.5em;
  line-height: initial;

  &.total {
    background-color: $circle100;
  }

  &.badge:empty {
    display: block;
  }
}

.border-wrapper {
  border: 1px solid;
  border-radius: 10px;
}

.shadow-box-table {
  overflow: auto;
  table {
    border-collapse: separate;
    border-spacing: 0 0.7em;

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-style: none;
    }

    .deposit {
      background-color: $green;
    }

    thead {
      background: #fff;
      border-style: none;
      tr {
        background-color: #f3f3f3;
      }
    }

    tbody {
      tr {
        background: #fff;
        transition: 0.2s ease-in-out;

        &:hover {
          transform: translateY(-5px);
          box-shadow: $shadow;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
}

.summary-totals {
  .card {
    padding: 10px;
    text-align: center;
    max-width: 200px;

    > h3:first-of-type {
      color: $accent;
    }
  }
}

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #00000061;
  z-index: 300;

  > div {
    position: relative;
    padding: 30px;
    box-shadow: 8px 14px 2px -4px #757575;

    &:hover {
      box-shadow: 16px 20px 4px -6px #696969bf;
    }

    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.muted {
  opacity: 0.7;
}

.mr-auto {
  margin-right: 3rem;
}

.fg-1 {
  flex-grow: 1;
}
.fg-2 {
  flex-grow: 2;
}
.fg-3 {
  flex-grow: 3;
}
.fg-4 {
  flex-grow: 4;
}
.fg-5 {
  flex-grow: 5;
}

.mw-150 {
  max-width: 150px;
}
.mw-200 {
  max-width: 200px;
}
.mw-250 {
  max-width: 250px;
}
.mw-300 {
  max-width: 300px;
}

.mw-400 {
  max-width: 400px;
}

.mw-450 {
  max-width: 450px;
}

.mw-500 {
  max-width: 500px;
}

ul {
  &.dashes {
    padding-left: 1rem;
    list-style: armenian;
  }
  &.no-decoration {
    padding-left: 1rem;
    list-style: none;
  }
}

.hide {
  display: none;
}

.edit {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230000008c' viewBox='0 -1 401.52289 401' width='401pt'%3E%3Cpath d='m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0'/%3E%3Cpath d='m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0'/%3E%3C/svg%3E");
  background-size: contain;

  &.white {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 -1 401.52289 401' width='401pt'%3E%3Cpath d='m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0'/%3E%3Cpath d='m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0'/%3E%3C/svg%3E");
    background-size: contain;
  }
}

.input-button-wrapper {
  > * {
    display: inline-block;
  }
  button {
    margin: -70px 0 0 10px;
  }
}

input:disabled {
  border: none;
  // padding: 0.375rem 0;
}

textarea:disabled {
  background: transparent !important;
}

input[type="email"] {
  min-width: 250px;
}

input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

select {
  &.form-select {
    &.color-select {
      color: #fff;
    }

    &.bg-warning,
    &.bg-info {
      color: #000;
    }
  }
}

.required {
  &::after {
    content: "*";
    color: red;
    margin-left: 5px;
  }
}

.error {
  color: red;
}

span {
  &.error {
    margin-left: 5px;
  }
}

.text-edit-wrapper textarea {
  padding: 10px;
  border: $border;
  border-radius: 10px;
  margin-bottom: 2em;
}

.btn {
  &.btn-edit {
    display: inline-flex;
    align-items: center;
    height: 39px;
  }

  &.btn-add {
    font-size: 1.8em;
  }

  &.dashed {
    border: $border;
  }

  &.btn-profile {
    img {
      width: 30px;
    }
  }
}

footer {
  position: fixed;
  z-index: 200;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: gainsboro;
}

@media all and (max-width: 1400px) {
  .card-wrapper {
    max-width: 933px;
  }
}

@media all and (max-width: 992px) {
  .card-wrapper {
    max-width: 600px;

    &.center {
      justify-content: flex-start;
    }
  }

  .container {
    max-width: 96%;
  }
}

@media all and (max-width: 900px) {
  .new-envelope {
    .circle-graph-wrapper {
      width: 100%;
    }
    .no-wrap {
      flex-flow: row wrap;
    }
  }

  .card-wrapper {
    flex-flow: row wrap;

    .summary-card,
    .card {
      min-width: 46%;
    }
  }
}

@media all and (max-width: 795px) {
  .circle-graph-wrapper {
    width: 100%;
    margin: 0 auto;
  }
}

@media all and (max-width: 767px) {
  .flex-wrapper {
    justify-content: center;
  }

  .pie-chart-wrapper {
    min-width: 100%;
  }

  .mobile-calendar {
    display: block;

    &.sticky {
      position: fixed;
      top: 79px;
      right: 0;
      width: 51px;
      height: 50px;
      padding: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid rgba(0, 0, 0, 0.125);
      box-shadow: 8px 14px 2px -4px #7d7d7d4d;
      background: $accent;
      z-index: 50;
    }

    img {
      width: 40px;
    }
  }

  .date-range-container {
    justify-content: center;
    text-align: center;
    select {
      max-width: 100%;
    }
  }

  .date-wrapper {
    display: none;

    &.show {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      background: white;
      justify-content: center;
      transition: 0.3s ease-in-out;
      z-index: 100;

      .btn-close {
        position: fixed;
        top: 20px;
        right: 20px;
        font-size: 2em;
      }

      .react-datepicker {
        margin-top: 150px;
        transform: scale(120%);
      }
    }
  }

  .envelope {
    form {
      > .flex-wrapper:first-of-type {
        flex-flow: column-reverse;
        button {
          justify-content: center;
          margin: 2em 0;
        }
      }
    }
  }

  .info-tag {
    padding: 10px;
    font-size: 1em;
  }

  .edit-mobile-wrapper {
    align-items: center;
    .btn-edit {
      flex-grow: 10;
    }
    .btn-close {
      flex-grow: 1;
      padding: 10px;
      margin: 0 0 0 10px !important;
      border: 1px solid gainsboro;
    }
  }
}

@media all and (max-width: 720px) {
  .card-wrapper {
    .summary-card {
      max-width: 46%;
      width: 100%;

      .card {
        max-width: 100%;
      }
    }
    .card {
      max-width: 46%;
      width: 100%;
    }
  }
}

@media all and (max-width: 671px) {
  .card-wrapper {
    .summary-card {
      .card:first-of-type {
        padding-top: 0 !important;
      }
    }

    .summary-card,
    .card {
      max-width: 100%;
    }
    .card {
      .circle-graph {
        order: 1;
        .circle {
          background-image: none !important;
          border-radius: 0;
          width: 100%;
          height: 10px;
          margin-bottom: 40px;

          .mobile-line {
            display: block;
            height: 10px;
            background-color: #eae9e9;
            margin-left: auto;
          }

          &::before {
            display: none;
          }

          .amount {
            position: absolute;
            top: 20px;
            width: 100%;
            padding: 0 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: none;

            h3 {
              margin-right: 5px;
            }

            small {
              display: none;
            }
          }
        }
      }
      .card-text {
        display: none;
      }
      .card-body {
        order: 2;
      }
      > .flex-wrapper {
        order: 3;
        position: absolute;
        bottom: 5px;
        left: 0;
        flex-flow: row-reverse;
        width: auto !important;
      }
      .card-input-wrapper {
        &:last-of-type {
          margin-bottom: 33px;
        }
      }
    }
  }
}

@media all and (max-width: 576px) {
  nav {
    .navbar-collapse {
      height: auto;
      overflow: hidden;
    }

    .collapse:not(.show) {
      display: block;
      height: 0;
      transition: height 0.3s ease-in-out;
    }

    .collapsed {
      height: 120px;
      transition: height 0.3s ease-in-out;
    }
  }

  .circle-graph-wrapper {
    margin: 0;
  }

  .new-envelope {
    .flex-wrapper {
      justify-content: flex-start;
      flex-flow: column;
    }
  }

  .edit-mobile-wrapper {
    align-items: center;
    .btn-edit {
      flex-grow: 10;
    }
    .btn-close {
      flex-grow: 1;
      padding: 10px;
      margin: 0 0 0 10px !important;
      border: 1px solid gainsboro;
    }
  }

  .summary-totals {
    .card {
      max-width: 100%;
    }
  }
}
